import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import { theme, ThemeColorKeyType, ThemeType } from 'styles';
import { ButtonProps, ButtonShape, ButtonSize, ButtonWrapper } from 'UI/Button/Button';
import { Icon } from 'UI/Icon';
import { useBreakpointSelector } from 'utils';

const buttonHeight: Record<ButtonSize, number> = {
  small: 24,
  middle: 32,
  large: 40,
  xlarge: 50,
};

const buttonPadding: Record<ButtonSize, number> = {
  small: 7,
  middle: 15,
  large: 15,
  xlarge: 15,
};

const buttonShape: Record<ButtonShape, string> = {
  square: '3px',
  semiRound: '10px',
  round: '23px',
  circle: '50%',
  default: '3px',
};

const common = css<ButtonProps>`
  font-size: ${() => useBreakpointSelector()([14, 16]) + 'px'};
  font-family: NouvelR, serif;
  font-weight: 500;
  width: ${({ stretch }) => (stretch === true ? '100%' : 'inherit')};
  height: ${({ size }) => buttonHeight[size ?? 'large']}px;
  text-align: center;
  line-height: normal;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  padding: 0 ${({ size }) => `${buttonPadding[size ?? 'large']}px 0 ${buttonPadding[size ?? 'large']}px`};
  border-radius: ${({ shape }) => `${buttonShape[shape ?? 'default']}`};
  align-self: ${({ alignSelfFlexEnd }) => alignSelfFlexEnd && 'flex-end'};
  white-space: ${({ wrap }) => wrap ?? 'nowrap'};
`;

// multiplier in percent
const selectMultiplier = (multiplier: number, theme: ThemeType) => (value: ThemeColorKeyType) => {
  if (multiplier === 100) {
    return theme.color[value];
  }
  if (multiplier > 100) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return theme.color[`${value}_light_${multiplier}`];
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return theme.color[`${value}_dark_${multiplier}`];
};

const getOffset = (isLight: boolean, offset: number) => 100 + (isLight ? -offset : offset);

const buttonStyle = (
  bgColor: ThemeColorKeyType,
  color: ThemeColorKeyType,
  border: ThemeColorKeyType | undefined,
  multiplier: number,
) => css`
  background-color: ${({ theme }) => selectMultiplier(multiplier, theme)(bgColor)};
  color: ${({ theme }) => selectMultiplier(multiplier, theme)(color)};
  border: ${({ theme }) => (border ? `solid 1px ${selectMultiplier(multiplier, theme)(border)}` : 'none')};
`;

const buttonStateStyles = (
  bgColor: ThemeColorKeyType,
  color: ThemeColorKeyType,
  border: ThemeColorKeyType | undefined,
  isLight: boolean,
) => {
  return css`
    ${buttonStyle(bgColor, color, border, 100)}
    :hover {
      ${buttonStyle(bgColor, color, border, getOffset(isLight, 20))}
    }

    :focus {
      ${buttonStyle(bgColor, color, border, getOffset(isLight, 30))}
    }

    :active {
      ${buttonStyle(bgColor, color, border, getOffset(!isLight, 30))}
    }

    :disabled {
      ${buttonStyle('grey65', 'grey85', 'grey85', getOffset(false, 20))}
    }
  `;
};

export const BlueButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('clear_blue', 'white', 'clear_blue', true)};
`;

export const WhiteBlueButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${({ outlined }) => buttonStateStyles('white', 'clear_blue', outlined ? 'clear_blue' : 'white', true)};
`;

export const YellowButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('brand', 'brand_black', 'brand_alt', true)};
`;

export const BlackButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('brand_black', 'white', 'grey20', false)};
`;

export const WhiteButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('white', 'brand_black', 'brand_black', true)};
`;

export const RedButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('red', 'brand_black', 'brand_black', true)};
`;

export const ErrorButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('white', 'error', 'error', false)};
`;

export const GreyButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('grey85', 'brand_black', undefined, true)};
`;

export const LightGreyButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('grey95', 'brand_black', undefined, true)};
`;

export const DropDownButton = styled(ButtonWrapper)<ButtonProps>`
  ${common};
  ${buttonStateStyles('grey100', 'brand_black', 'grey85', true)};

'&.ant-dropdown-open, :active, :focus': {
  ${buttonStateStyles('grey100', 'brand_black', 'brand_light', true)};
  box-shadow: 0 0 0 2px ${'brand_background'};
} ':hover': {
  ${buttonStateStyles('grey100', 'brand_black', 'brand_light', true)};
},
`;

export const LeftButton = ({ disabled, onClick }: { disabled?: boolean; onClick?: () => void }) => {
  return (
    <SquareButton
      disabled={disabled}
      onClick={onClick}
      icon={
        <Icon
          IconComponent={ChevronLeftIcon}
          disabled={disabled}
          color={disabled ? theme.color.grey60 : theme.color.grey45}
          size={16}
          mr={2}
        />
      }
    ></SquareButton>
  );
};

export const RightButton = ({ disabled, onClick }: { disabled?: boolean; onClick?: () => void }) => {
  return (
    <SquareButton
      disabled={disabled}
      onClick={onClick}
      icon={
        <Icon
          IconComponent={ChevronRightIcon}
          disabled={disabled}
          color={disabled ? theme.color.grey60 : theme.color.grey45}
          size={16}
          ml={2}
        />
      }
    ></SquareButton>
  );
};

interface SquareButtonProps {
  disabled?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}

const SquareButton = ({ disabled, icon, onClick }: SquareButtonProps) => {
  return (
    <SquareButtonStyled disabled={disabled} onClick={onClick}>
      {icon}
    </SquareButtonStyled>
  );
};

const SquareButtonStyled = styled.div<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? theme.color.grey95 : theme.color.grey100)};
  border-radius: 4px;
  border: 1px solid ${({ disabled }) => (disabled ? theme.color.grey60 : theme.color.grey45)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &:hover {
    background-color: ${({ disabled }) => !disabled && theme.color.grey95};
  }
`;
