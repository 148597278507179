/* eslint-disable max-len */
import { GetPlatesForReferenceResponse } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetPlatesForReferenceResponse';
import { GetReuseStockResponse } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetReuseStockResponse';
import { CurrentReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/CheckIfStocksAreStillUpToDateRequest';
import { ReferenceStockTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferencesStockRequest';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { CheckIfStocksAreStillUpToDateResponse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/CheckIfStocksAreStillUpToDateResponse';
import { GetReferencesPriceResponse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/GetReferencesPriceResponse';
import {
  GetReferencesStocksResponse,
  ReferenceStock,
} from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/GetReferencesStocksResponse';
import { ReferencesDiscountsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/ReferencesDiscountsResponse';
import { RemovedReferencesDiscountsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/RemovedReferencesDiscountsResponse';
import { RepairPricesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/response/RepairPricesResponse';
import { put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { getLastSearchedVehicleKey, getVehicleContext } from 'domains/catalog/Catalog.store';
import { isRefNumberDeposit } from 'domains/references/DepositReference';
import {
  sendCheckIfStocksAreStillUpToDateRequest,
  sendGetCrossSellingReferences,
  sendGetPlatesForReference,
  sendGetReferencesDetailsRequest,
  sendGetReferencesPriceRequest,
  sendGetReferencesRepairPricesRequest,
  sendGetReferencesStocksRequest,
  sendGetReuseStocks,
} from 'domains/references/References.api';
import { sortStockWarehouses } from 'domains/references/References.mapper';
import * as actions from 'domains/references/References.store';
import {
  getPricesMap,
  getReferencesToStockInfos,
  getStocksMap,
  removeReferencesDiscounts,
  setCrossSellingReferences,
  setPlatesForReference,
  setPlatesForReferenceSearchStatus,
  setReferencesDiscounts,
  setReferencesDiscountsSearchStatus,
  setReferencesPriceData,
  setReferencesPriceNoDataStatus,
  setReferencesRepairPriceData,
  setReferencesRepairPriceNoDataStatus,
  setReferencesStocksData,
  setReferencesStocksNoDataStatus,
  setReuseStockData,
  setReuseStockNoDataStatus,
  setStockUpdateCheck,
} from 'domains/references/References.store';
import { getTradingProfile, getUserContext } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { hasData, LOADING, sagaGuard, select } from 'utils';

export function* fetchPricesAndStocks(
  tradingDataRequest: ReferenceTradingDataRequestDetail[],
  stockRequest: ReferenceStockTradingDataRequestDetail[],
): SagaIterator {
  const tradingProfile = yield* select(getTradingProfile);
  if (tradingProfile?.isComplete() !== true) {
    return;
  }
  yield put(
    actions.fetchReferencesStocksRequestSaga({
      data: stockRequest,
    }),
  );
  yield put(
    actions.fetchReferencesPriceRequestSaga({
      references: tradingDataRequest,
    }),
  );
}

export function* fetchReferencesPriceRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchReferencesPriceRequestSaga>): SagaIterator {
  const { references } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.isComplete()) return;
  const referenceNumbers = references.map((r) => r.referenceNumber);
  const prices = yield* select((state: RootState) => getPricesMap(state, referenceNumbers));
  const referencesToFetch = references.filter((r) => prices.get(r.referenceNumber) === undefined);

  if (referencesToFetch.length > 0) {
    yield put(
      setReferencesPriceNoDataStatus({
        referenceNumbers: referencesToFetch.map((r) => r.referenceNumber),
        status: LOADING,
      }),
    );
    yield put(sendGetReferencesPriceRequest(referencesToFetch));
  }
}

export function* fetchReferencesDiscountsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchReferencesDiscountsRequestSaga>): SagaIterator {
  const { references } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.isComplete()) return;
  const referenceNumbers = references.map((r) => r.referenceNumber);
  const prices = yield* select((state: RootState) => getPricesMap(state, referenceNumbers));
  const referencesToFetch = references.filter((r) => prices.get(r.referenceNumber) === undefined);

  if (referencesToFetch.length > 0) {
    yield put(
      setReferencesDiscountsSearchStatus({
        referenceNumbers: referencesToFetch.map((r) => r.referenceNumber),
        searchStatus: LOADING,
      }),
    );
    yield put(sendGetReferencesDetailsRequest(referencesToFetch));
  }
}

export function* fetchCrossSellingReferencesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchCrossSellingReferencesRequestSaga>): SagaIterator {
  const { references, vehicleKey } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state) => getVehicleContext(state, vehicleKey ?? ''));
  const transformReferences = references.map((r) => {
    return {
      sourceReference: r,
      crossSellingReferences: [],
    };
  });
  yield put(sendGetCrossSellingReferences({ references, userContext, vehicleContext }));
  yield put(setCrossSellingReferences({ crossSellingReferences: transformReferences, vehicleKey, status: LOADING }));
}

export function* fetchCrossSellingFileReferencesResponseSaga(action: WsResponse): SagaIterator {
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  yield put(setCrossSellingReferences({ crossSellingReferences: action.payload.groups, vehicleKey }));
}

export function* fetchCrossSellingReferencesResponseSaga(action: WsResponse): SagaIterator {
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  yield put(setCrossSellingReferences({ crossSellingReferences: [action.payload.group], vehicleKey }));
}

export function* fetchReferencesPriceResponseSaga(action: WsResponse): SagaIterator {
  const { referencesPrice }: GetReferencesPriceResponse = action.payload;
  yield put(setReferencesPriceData(referencesPrice));
}

export function* fetchReferencesRepairPriceRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchReferencesRepairPriceRequestSaga>): SagaIterator {
  const { references } = payload;
  yield put(
    setReferencesRepairPriceNoDataStatus({
      referenceNumbers: references.map((r) => r.referenceNumber),
      status: LOADING,
    }),
  );
  yield put(sendGetReferencesRepairPricesRequest(references));
}

export function* fetchReferencesRepairPriceResponseSaga(action: WsResponse): SagaIterator {
  const { referencesPrice }: RepairPricesResponse = action.payload;
  yield put(setReferencesRepairPriceData(referencesPrice));
}

export function* fetchReferencesStocksRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchReferencesStocksRequestSaga>): SagaIterator {
  const { data, fetchEvenIfExists } = payload;
  const tradingProfile = yield* select(getTradingProfile);
  if (!tradingProfile?.isComplete()) return;
  const userContext = yield* select(getUserContext);
  const referenceNumbers = data.map((d) => d.referenceNumber);
  const stocks = yield* select((state: RootState) => getStocksMap(state, referenceNumbers));
  const referencesToFetch = (fetchEvenIfExists === true
    ? data
    : data.filter((r) => stocks.get(r.referenceNumber) === undefined)
  ).filter((r) => !isRefNumberDeposit(r.referenceNumber));

  // pretend like no discount is present, loading state to be done maybe in future (spinners not required yet)
  if (referencesToFetch.length > 0) {
    yield put(
      setReferencesStocksNoDataStatus({
        references: referencesToFetch?.map((ref) => ref.referenceNumber),
        status: LOADING,
      }),
    );

    yield put(sendGetReferencesStocksRequest(referencesToFetch, userContext));
  }
}

export function* fetchReferencesStocksResponseSaga(action: WsResponse<GetReferencesStocksResponse>): SagaIterator {
  const { referencesStocks }: GetReferencesStocksResponse = action.payload;

  const sortedStockWarehousesArray: ReferenceStock[] = referencesStocks?.map((st) => {
    const warehouses = st.warehouses;
    const sortedWarehouses = hasData(warehouses) ? [...warehouses].sort(sortStockWarehouses) : warehouses;
    return { ...st, warehouses: sortedWarehouses };
  });

  yield put(setReferencesStocksData(sortedStockWarehousesArray));
}

export function* fetchReuseStocksRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchReuseStocksRequestSaga>): SagaIterator {
  const { referenceNumbers, nodeId } = payload;
  yield put(sendGetReuseStocks(referenceNumbers, nodeId));
  yield put(
    setReuseStockNoDataStatus({
      referenceNumbers,
      status: LOADING,
    }),
  );
}

export function* fetchReuseStocksResponseSaga(action: WsResponse<GetReuseStockResponse>): SagaIterator {
  yield put(setReuseStockData(action.payload.references));
}

export function* fetchReferenceDiscountsResponseSaga(action: WsResponse<ReferencesDiscountsResponse>): SagaIterator {
  yield put(setReferencesDiscounts(action.payload));
}

export function* fetchReferenceDiscountsRemoveResponseSaga(
  action: WsResponse<RemovedReferencesDiscountsResponse>,
): SagaIterator {
  yield put(removeReferencesDiscounts(action.payload.references));
}

export function* fetchPlatesForReferenceRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchPlatesForReferenceRequestSaga>): SagaIterator {
  const { vehicleKey, referenceNumber } = payload;
  yield put(setPlatesForReferenceSearchStatus({ vehicleKey, reference: referenceNumber, status: LOADING }));
  const vehicleContext = yield* select((state) => getVehicleContext(state, vehicleKey));
  const userContext = yield* select(getUserContext);
  yield put(
    sendGetPlatesForReference({
      vehicleContext,
      userContext,
      referenceNumber,
    }),
  );
}

export function* fetchPlatesForReferenceResponseSaga(action: WsResponse<GetPlatesForReferenceResponse>): SagaIterator {
  const { vehicleContext, referenceNumber, plates } = action.payload;
  yield put(setPlatesForReference({ vehicleKey: vehicleContext.vehicleKey, reference: referenceNumber, plates }));
}

export function* checkIfStocksAreStillUpToDateRequestSaga({
  payload,
}: ReturnType<typeof actions.checkIfStocksAreStillUpToDateRequestSaga>): SagaIterator {
  const { requestId, references } = payload;

  const refNumbers = references.map((ref) => ref.referenceNumber);
  const referencesToStockInfos = yield* select((state: RootState) => getReferencesToStockInfos(state, refNumbers));
  const currentStocks: CurrentReferenceStock[] = referencesToStockInfos
    .map((refStock) => {
      if (refStock?.data === undefined) return undefined;
      return {
        reference: refStock.data.reference,
        requestedQuantity: references.find((r) => r.referenceNumber === refStock.data?.reference)?.quantity ?? 1,
        type: references.find((r) => r.referenceNumber === refStock.data?.reference)?.type ?? 'STANDARD',
        warehouses: refStock.data.warehouses,
      };
    })
    .filter((i) => i !== undefined) as CurrentReferenceStock[];
  yield put(setStockUpdateCheck({ id: requestId, result: LOADING }));
  const userContext = yield* select(getUserContext);

  yield put(
    sendCheckIfStocksAreStillUpToDateRequest({
      requestId,
      currentStocks,
      userContext,
    }),
  );
}

export function* checkIfStocksAreStillUpToDateResponseSaga(
  action: WsResponse<CheckIfStocksAreStillUpToDateResponse>,
): SagaIterator {
  const { requestId, referenceStockDiffs, updatedStockDetails } = action.payload;
  yield put(setReferencesStocksData(updatedStockDetails));
  yield put(setStockUpdateCheck({ id: requestId, result: referenceStockDiffs }));
}

export function* ReferencesSagas(): SagaIterator {
  yield takeEvery(actions.fetchReferencesPriceRequestSaga.type, sagaGuard(fetchReferencesPriceRequestSaga));
  yield takeEvery(actions.fetchReferencesPriceResponseSaga.type, sagaGuard(fetchReferencesPriceResponseSaga));
  yield takeEvery(
    actions.fetchCrossSellingReferencesRequestSaga.type,
    sagaGuard(fetchCrossSellingReferencesRequestSaga),
  );
  yield takeEvery(
    actions.fetchCrossSellingFileReferencesResponseSaga.type,
    sagaGuard(fetchCrossSellingFileReferencesResponseSaga),
  );
  yield takeEvery(
    actions.fetchCrossSellingReferencesResponseSaga.type,
    sagaGuard(fetchCrossSellingReferencesResponseSaga),
  );
  yield takeEvery(actions.fetchReferencesRepairPriceRequestSaga.type, sagaGuard(fetchReferencesRepairPriceRequestSaga));
  yield takeEvery(
    actions.fetchReferencesRepairPriceResponseSaga.type,
    sagaGuard(fetchReferencesRepairPriceResponseSaga),
  );
  yield takeEvery(actions.fetchReferencesStocksRequestSaga.type, sagaGuard(fetchReferencesStocksRequestSaga));
  yield takeEvery(actions.fetchReferencesStocksResponseSaga.type, sagaGuard(fetchReferencesStocksResponseSaga));
  yield takeEvery(actions.fetchReuseStocksRequestSaga.type, sagaGuard(fetchReuseStocksRequestSaga));
  yield takeEvery(actions.fetchReuseStocksResponseSaga.type, sagaGuard(fetchReuseStocksResponseSaga));
  yield takeEvery(actions.fetchReferencesDiscountsRequestSaga.type, sagaGuard(fetchReferencesDiscountsRequestSaga));
  yield takeEvery(actions.fetchReferenceDiscountsResponse.type, sagaGuard(fetchReferenceDiscountsResponseSaga));
  yield takeEvery(
    actions.fetchReferenceDiscountsRemoveResponse.type,
    sagaGuard(fetchReferenceDiscountsRemoveResponseSaga),
  );
  yield takeEvery(
    actions.checkIfStocksAreStillUpToDateRequestSaga.type,
    sagaGuard(checkIfStocksAreStillUpToDateRequestSaga),
  );
  yield takeEvery(
    actions.checkIfStocksAreStillUpToDateResponseSaga.type,
    sagaGuard(checkIfStocksAreStillUpToDateResponseSaga),
  );
  yield takeEvery(actions.fetchPlatesForReferenceRequestSaga.type, sagaGuard(fetchPlatesForReferenceRequestSaga));
  yield takeEvery(actions.fetchPlatesForReferenceResponseSaga.type, sagaGuard(fetchPlatesForReferenceResponseSaga));
}
