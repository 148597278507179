/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Collapse, CollapseProps } from 'antd-v5';
import { useDebouncedCallback } from 'use-debounce';
import { RootState } from 'app/AppStore';
import { CircleXMarkOutlined, SearchNoResultIcon } from 'assets/icons';
import Loader from 'components/Loader';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import { SIDEBAR_WIDTH } from 'components/Page/PageSideBar/PageSideBar';
import { IAMFulltextSearchResult } from 'domains/catalog/Catalog.types';
import { getIAMReferences, getPricesMap, getStocksMap } from 'domains/references';
import { SparePartsViewType } from 'domains/user';

import { CompareTable } from 'pages/CatalogPage/IAM/FullTextSearchResult/CompareTable';
import { HeaderWrapper } from 'pages/CatalogPage/IAM/FullTextSearchResult/CompareTable.styled';
import { IamProductCard } from 'pages/CatalogPage/IAM/FullTextSearchResult/IAMProductCard';
import IAMPlateReferenceCard from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/PlateReferenceCard';
import {
  getFilteredMotrioReferences,
  getFilteredOtherBrandsReferences,
  sortIAMReferences,
} from 'pages/CatalogPage/IAM/SubcategorySection/SparePartsSection/ReferenceCardsContainer/ReferenceCardsContainerUtils';
import { SIDEBAR_ZINDEX, theme } from 'styles';
import {
  Box,
  CenteredSpin,
  Flex,
  Icon,
  LeftButton,
  MarginBox,
  Modal,
  MODAL_HEADER_HEIGHT,
  RightButton,
  Text,
} from 'UI';
import { getData, isLoading } from 'utils';

interface SearchResultSectionProps {
  searchResultGroup: IAMFulltextSearchResult;
  sparePartsView: SparePartsViewType;
}

interface EquivalentSectionProps {
  sparePartsView: SparePartsViewType;
  equivalentReferences: string[] | undefined;
  additionalEquivalencesLoading: boolean;
  handleClickCompare?: (reference: string) => void;
  hasMainReference?: boolean;
}

interface ComparativeTableReferences {
  firstReference: string;
  secondReference: string;
  thirdReference: string;
}

const SearchResultSection = ({ searchResultGroup, sparePartsView }: SearchResultSectionProps) => {
  const equivalentReferences = getData(searchResultGroup.equivalentReferences);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const mainReference = searchResultGroup.mainReferences?.[0];
  const compareTableInitialState = {
    firstReference: mainReference ?? '',
    secondReference: '',
    thirdReference: '',
  };
  const [compareTableReferences, setCompareTableReferences] = useState<ComparativeTableReferences>(
    compareTableInitialState,
  );

  const handleClickCompare = (reference: string) => {
    setIsModalOpen(true);
    setCompareTableReferences({ ...compareTableReferences, secondReference: reference });
  };
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Text type={'h2'}>{`${searchResultGroup.groupName?.toUpperCase()}`}</Text>
          <MainSection searchResultGroup={searchResultGroup} sparePartsView={sparePartsView} />
        </>
      ),
      children: (
        <EquivalentSection
          sparePartsView={sparePartsView}
          equivalentReferences={equivalentReferences}
          additionalEquivalencesLoading={searchResultGroup.additionalEquivalencesLoading}
          handleClickCompare={handleClickCompare}
          hasMainReference={!!mainReference}
        />
      ),
    },
  ];

  const ModalHeader = () => {
    const { t } = useTranslation();
    return (
      <HeaderWrapper>
        <Text type="h2_bold">{t('catalog.search.iam.compare_table.title', 'Comparative')}</Text>
        <Text type="h5_light">
          {t('catalog.search.iam.compare_table.description', 'Select the reference you want to compare')}
        </Text>
      </HeaderWrapper>
    );
  };

  const handleSelect = (referenceNumber: string, columnIndex?: number) => {
    switch (columnIndex) {
      case 2:
        setCompareTableReferences({ ...compareTableReferences, secondReference: referenceNumber });
        break;
      case 3:
        setCompareTableReferences({ ...compareTableReferences, thirdReference: referenceNumber });
        break;
      default:
        return;
    }
  };

  return (
    <>
      <Collapse
        items={items}
        defaultActiveKey={['1']}
        expandIconPosition={'end'}
        collapsible={'icon'}
        style={{
          background: 'white',
          padding: '15px 15px',
          borderRadius: '14px',
          boxShadow: `0 4px 10px 0 ${theme.color.grey75}`,
        }}
        bordered={true}
      />
      <Modal
        title={<ModalHeader />}
        footer={null}
        maskClosable
        closable
        closeIcon={<Icon IconComponent={CircleXMarkOutlined} />}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          setCompareTableReferences(compareTableInitialState);
        }}
        zIndex={SIDEBAR_ZINDEX - 1}
        backgroundColor={theme.color.white}
        style={{
          position: 'absolute',
          top: HEADER_HEIGHT + MODAL_HEADER_HEIGHT,
          left: SIDEBAR_WIDTH,
          maxWidth: window.innerWidth - 2 * SIDEBAR_WIDTH,
        }}
      >
        <MarginBox mt={48}>
          <CompareTable
            selectedReferences={compareTableReferences}
            compareReferencesList={equivalentReferences}
            handleSelect={handleSelect}
          />
        </MarginBox>
      </Modal>
    </>
  );
};

const MainSection = ({ searchResultGroup, sparePartsView }: SearchResultSectionProps) => {
  const { t } = useTranslation();

  const hasRefs = (searchResultGroup.mainReferences?.length ?? 0) > 0;

  const renderNoMainProduct = useMemo(
    () => (
      <Flex direction="column" justify="center" align="center">
        <Icon IconComponent={SearchNoResultIcon} size={80} />
        <Box height={12} />
        <Text type="h2">
          {t('catalog.search.iam.main_products.search.no_result.title', 'References currently unavailable.')}
        </Text>
        <Text type="h3_dim">
          {t(
            'catalog.search.iam.main_products.search.no_result.description',
            'We have listed our best fit Exadis catalog',
          )}
        </Text>
      </Flex>
    ),
    [t],
  );

  const renderGroupMainSection = useMemo(() => {
    if (!hasRefs) {
      return renderNoMainProduct;
    }
    return searchResultGroup.mainReferences?.map((mainRef) => (
      <IAMPlateReferenceCard
        key={mainRef}
        referenceNumber={mainRef}
        sparePartsView={sparePartsView}
        bordered={false}
        isIamSearchResult
      />
    ));
  }, [hasRefs, renderNoMainProduct, searchResultGroup.mainReferences, sparePartsView]);

  return (
    <MarginBox mx={15} my={15}>
      {searchResultGroup.loading ? <Loader height="10vh" size="default" /> : renderGroupMainSection}
    </MarginBox>
  );
};

const EquivalentSection = ({
  sparePartsView,
  equivalentReferences,
  additionalEquivalencesLoading,
  handleClickCompare,
  hasMainReference,
}: EquivalentSectionProps) => {
  const { t } = useTranslation();
  const references = useSelector((state: RootState) =>
    getIAMReferences(state, { vehicleKey: undefined, referenceNumbers: equivalentReferences }),
  );
  const prices = useSelector((state: RootState) => getPricesMap(state, equivalentReferences ?? []));
  const stocks = useSelector((state: RootState) => getStocksMap(state, equivalentReferences ?? []));

  const sortedReferences = useMemo(() => {
    const motrioReferences = sortIAMReferences(getFilteredMotrioReferences(references, prices, stocks), stocks);
    const otherBrandsReferences = sortIAMReferences(getFilteredOtherBrandsReferences(references), stocks);
    return [...motrioReferences, ...otherBrandsReferences].map((r) => r.referenceNumber);
  }, [prices, references, stocks]);

  const isAnyReferenceLoading = references.some((r) => isLoading(r.detailStatus));

  const containerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const itemWidth = 332;
  const defaultDisplayedCardCount = 3;
  const displayedCardsCount = containerRef.current
    ? Math.floor(containerRef.current.clientWidth / itemWidth)
    : defaultDisplayedCardCount;
  const scrollStepSize = (displayedCardsCount > 1 ? displayedCardsCount - 1 : 1) * itemWidth;

  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(false);

  const updateButtonStates = (scrollLeft: number) => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth } = containerRef.current;
      setIsLeftDisabled(scrollLeft <= 0);
      setIsRightDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const setNewWindowWidth = useDebouncedCallback(() => {
    setWindowWidth(window.innerWidth);
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', setNewWindowWidth);
    return () => {
      window.removeEventListener('resize', setNewWindowWidth);
    };
  }, [setNewWindowWidth]);

  useEffect(() => {
    if (containerRef.current) {
      updateButtonStates(containerRef.current.scrollLeft);
    }
  }, [windowWidth]);

  const renderSectionPlate = useMemo(() => {
    const handleLeftButtonClick = () => {
      if (containerRef.current) {
        const { scrollLeft } = containerRef.current;
        const scrollAmount = scrollLeft - scrollStepSize;
        containerRef.current.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
        updateButtonStates(scrollAmount);
      }
    };

    const handleRightButtonClick = () => {
      if (containerRef.current) {
        const { scrollLeft } = containerRef.current;
        const scrollAmount = scrollLeft + scrollStepSize;
        containerRef.current.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
        updateButtonStates(scrollAmount);
      }
    };

    return (
      <MarginBox mx={15}>
        <Flex direction={'row'} justify={'space-between'} gap={48}>
          <Flex direction={'column'}>
            <Flex direction={'row'}>
              <Text type={'h3_bold_clear_blue'}>
                {t('catalog.search.iam.equivalent_products.title', 'Equivalent products')}
              </Text>
              <Box width={10} />
              <Text type={'h5_light'}>
                {t('catalog.search.iam.equivalent_products.products_count', '({{count}} products)', {
                  count: equivalentReferences?.length,
                })}
              </Text>
            </Flex>
            <Box height={4} />
            <Text type={'h6_light'}>
              {t(
                'catalog.search.iam.equivalent_products.description',
                'All listed products have the same or substantially the same external appearance, and performance characteristics as manufacturer parts.',
              )}
            </Text>
          </Flex>
          {!(isLeftDisabled && isRightDisabled) && (
            <Flex size={0} direction={'row'} justify={'flex-end'} gap={6}>
              <LeftButton disabled={isLeftDisabled} onClick={handleLeftButtonClick} />
              <RightButton disabled={isRightDisabled} onClick={handleRightButtonClick} />
            </Flex>
          )}
        </Flex>
      </MarginBox>
    );
  }, [equivalentReferences?.length, isLeftDisabled, isRightDisabled, scrollStepSize, t]);

  if (isAnyReferenceLoading || additionalEquivalencesLoading) {
    return <CenteredSpin />;
  }
  if (!equivalentReferences || equivalentReferences?.length === 0) {
    return renderSectionPlate;
  }

  return (
    <>
      {renderSectionPlate}
      <Box height={12} />
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'hidden',
          padding: '15px 15px 15px 15px',
          margin: '0px 15px',
          gap: '32px',
        }}
      >
        {sortedReferences.map((eqRef) => {
          return (
            <div key={eqRef}>
              <IamProductCard
                referenceNumber={eqRef}
                sparePartsView={sparePartsView}
                handleClickCompare={handleClickCompare}
                showCompare={hasMainReference}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SearchResultSection;
