import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import Filter from 'components/Filter';
import { FiltersRange, getIamSearchResultFacetMap, TextFacetLocal } from 'components/Filter/Filter.types';
import { FilterHelper } from 'components/Filter/FilterHelper';
import { IAMFulltextSearchResult } from 'domains/catalog/Catalog.types';
import { getIAMReferences, IAMReferenceLocal } from 'domains/references';
import { getSparePartsView } from 'domains/user';
import SearchResultSection from 'pages/CatalogPage/IAM/FullTextSearchResult/SearchResultSection';
import { Container, defaultPadding, Flex, InfiniteScroll, MarginBox, Text, useInfiniteScroll } from 'UI';
import ViewTabs from 'UI/Tabs/ViewTabs';
import { useLarge } from 'utils';
import { useResetScrollSharp } from 'utils/hooks/useResetScroll';

const PAGING_SIZE = 10;

interface SearchResultContainerProps {
  searchResult: IAMFulltextSearchResult[];
}

const Filters = {
  Supplier: {
    id: 'supplierLabel',
    groupFn: (refDetail: IAMReferenceLocal[]) => FilterHelper.calculateFrequencies(refDetail, 'supplier'),
    filterFn: (refDetail: IAMReferenceLocal[], toMatch: string[] | undefined) =>
      FilterHelper.filterData(refDetail, 'supplier', toMatch),
  },
  Family: {
    id: 'familyLabel',
    groupFn: (refDetail: IAMReferenceLocal[]) => FilterHelper.calculateFrequencies(refDetail, 'families.label'),
    filterFn: (refDetail: IAMReferenceLocal[], toMatch: string[] | undefined) =>
      FilterHelper.filterData(refDetail, 'families.label', toMatch),
  },
} as const;

const findRefDetailByNumber = (refNumber: string, allRefDetails: IAMReferenceLocal[]) => {
  return allRefDetails.find((detail) => detail.referenceNumber === refNumber);
};

const SearchResultContainer = ({ searchResult }: SearchResultContainerProps) => {
  const { t } = useTranslation();
  const large = useLarge();
  const [activeTextFilters, setActiveTextFilters] = useState<Map<string, string[]>>(new Map<string, string[]>());
  const [displayedTextFilters, setDisplayedTextFilters] = useState<TextFacetLocal[]>([]);
  const [filteredData, setFilteredData] = useState<IAMFulltextSearchResult[]>([]);

  const referenceNumbers = useMemo(() => {
    return searchResult.flatMap((result) => result.mainReferences || []);
  }, [searchResult]);

  const allRefDetails = useSelector((state: RootState) =>
    getIAMReferences(state, {
      vehicleKey: undefined,
      referenceNumbers,
    }),
  );

  const sparePartsView = useSelector(getSparePartsView);
  const { label } = useParams<{
    query: string;
    label: string;
  }>();

  useEffect(() => {
    const displayedSearchResult = searchResult.filter(
      (result) =>
        (result.mainReferences && result.mainReferences.length > 0) ||
        (result.equivalentReferences && result.equivalentReferences.length > 0),
    );

    const refNumber = displayedSearchResult.flatMap((result) => result.mainReferences || []);
    const refDetail = refNumber
      .map((item) => findRefDetailByNumber(item, allRefDetails))
      .filter((i): i is IAMReferenceLocal => i !== undefined);

    const loadedFilters = Object.entries(Filters).map(([_, filter], index) => ({
      active: true,
      order: index,
      id: filter.id,
      type: 'TEXT',
      items: filter.groupFn(refDetail),
    })) as TextFacetLocal[];

    setDisplayedTextFilters(loadedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  useEffect(() => {
    const displayedSearchResult = searchResult.filter(
      (result) =>
        (result.mainReferences && result.mainReferences.length > 0) ||
        (result.equivalentReferences && result.equivalentReferences.length > 0),
    );

    const filteredToDisplay = displayedSearchResult.filter((i) => {
      if (activeTextFilters.size) {
        const refBrands = i.mainReferences?.flatMap((r) => {
          return findRefDetailByNumber(r, allRefDetails);
        });

        const remainingData = Object.values(Filters).reduce(
          (previousData, filter) => filter.filterFn(previousData, activeTextFilters.get(filter.id)),
          refBrands as IAMReferenceLocal[],
        );

        return remainingData.length > 0;
      } else {
        return true;
      }
    });

    setFilteredData(filteredToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTextFilters, searchResult]);

  const { currentData, hasMore, loadMore } = useInfiniteScroll<IAMFulltextSearchResult>({
    data: filteredData ?? [],
    chunkSize: PAGING_SIZE,
  });

  useResetScrollSharp();

  return (
    <Container padding={defaultPadding}>
      <MarginBox mt={60}>
        <Flex direction={'row'} align={'baseline'} gap={5}>
          <Trans
            i18nKey="catalog.search.iam.result.title"
            tOptions={{ number: filteredData.length, keyword: label }}
            components={{
              large: <Text type="h2_bold" />,
              normal: <Text type="h3_dim" />,
              normal_bold: <Text type="h3" />,
            }}
          >
            {`<large>{{ number }}</large> <normal>result for your search</normal> <normal_bold>{{ keyword }}</normal_bold>`}
          </Trans>
        </Flex>
        <ViewTabs />
        <Flex gap={10} direction={large ? 'row' : 'column'}>
          <Flex direction={'column'} size={0}>
            <Filter
              filters={{ rangeFilters: new Map<string, FiltersRange>(), textFilters: activeTextFilters }}
              textFilters={displayedTextFilters}
              rangeFilters={undefined}
              setTextFilters={(id, item) => {
                setActiveTextFilters((prevFilters) => FilterHelper.updateTextFilter(id, item, prevFilters));
              }}
              resetFilters={() => {
                setActiveTextFilters(new Map<string, string[]>());
              }}
              loading={false}
              facetsMap={getIamSearchResultFacetMap(t)}
            />
          </Flex>

          <MarginBox>
            <InfiniteScroll hasMore={hasMore} loadMore={loadMore}>
              <Flex gap={20} direction={'column'}>
                {currentData.map((group: IAMFulltextSearchResult) => (
                  <SearchResultSection
                    key={group.groupName}
                    searchResultGroup={group}
                    sparePartsView={sparePartsView}
                  />
                ))}
              </Flex>
            </InfiniteScroll>
          </MarginBox>
        </Flex>
      </MarginBox>
    </Container>
  );
};

export default SearchResultContainer;
